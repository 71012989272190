import { Backdrop, Button, CircularProgress, createTheme, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import axios from "axios";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CadastroChutes from "./components/CadastroChutes";

import Header from "./components/Header";


import './Chutes.css';



function Chutes({fase}) { 
  const theme = createTheme({
    components: {
      // Name of the component
      MuiTableCell: {
        styleOverrides: {
     
          head: {
            // Some CSS
            fontSize: '1.1rem',
            color: 'grey',
            padding: '0'
            
          },
          body: {
            fontSize: '14px',
            color: 'white',
            textAlign: 'center',
            padding: '10px 0 0 0px',
            border: '1',
            fontFamily: 'Inter',
            lineHeight: '20px'
          }
        },
      },
    },
  });
  
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const[buttonDisabeld, setButtonDisabled] = useState(true);

  const [resultados] = useState([]);
  const handleResultado1 = (e) => {
    let valor;
    if (e.target.value === '' || e.target.value === null)
      valor = -1;
    else
      valor = parseInt(e.target.value)

    let index = resultados.findIndex((obj => obj.ID === parseInt(e.target.id)))
    if (index === -1){
      resultados.push(
        {
          'ID': parseInt(e.target.id),
          'R1': valor,
          'R2': -1
        }
      );
    }
    else{
      resultados[index].R1 = valor;
    }
    validaBotao();
  }
  const handleResultado2 = (e) => {
    let valor;
    if (e.target.value === '' || e.target.value === null)
      valor = -1;
    else
      valor = parseInt(e.target.value)

    let index = resultados.findIndex((obj => obj.ID === parseInt(e.target.id)))
    if (index === -1){
      resultados.push(
        {
          'ID': parseInt(e.target.id),
          'R1': -1,
          'R2': valor
        }
      );
    }
    else{
      resultados[index].R2 = valor;
    }
    validaBotao();
  }

  const validaBotao = () =>{
    let maxChute = 0;
    if (fase === "FaseGrupos"){
      maxChute = 48;
    }
    else{
      let array = [];
      for (var i = 0; i < infos.length; i++) {
        array = array.concat(infos[i].JOGOS.filter(obj => obj.RESULTADO1 === -1));
        //console.log();
        //if (array.length > 1)
        //  break;
      }

      maxChute = array.length;
    }
  
    if (resultados.length === maxChute){
      let r1 = resultados.filter(obj => obj.R1 < 0);
      let r2 = resultados.filter(obj => obj.R2 < 0);
      if (r1.length !== 0 || r2.length !== 0)
        setButtonDisabled(true);
      else
        setButtonDisabled(false);
    }
    else
      setButtonDisabled(true);
  }

  const handleEnviaChutes = () =>{
    setLoading(true);
    setOpen(true);
    setButtonDisabled(true);
    axios.post(process.env.REACT_APP_BACKEND+'backend/chutes/', 
    JSON.stringify(resultados),
      {headers: { 'Authorization': `Bearer ${localStorage.getItem("token_bolaodojuca")}` }}

      )
        .then(function (response) {
          localStorage.setItem("token_bolaodojuca",response.data);
          window.location.reload();
        })
        .catch(function (error) {
          if (error.response.status === 401){
            window.location.reload();
          }
          else{
            localStorage.removeItem("token_bolaodojuca");
            navigate('/');
          }
        });

  }

  const handleDialogClose = (e) => {
    setOpenDialog(false);
  }

  const [infos, setMessage] = useState([]);
  let navigate = useNavigate(); 
  useEffect(() => {
    setLoading(true);
    setOpen(true);
    axios.get(process.env.REACT_APP_BACKEND+'backend/chutes/lista_chutes.php', 
        { 
          headers: { 'Authorization': `Bearer ${localStorage.getItem("token_bolaodojuca")}` }
        })
        .then(function (response) {
          if (fase === 'FaseGrupos')
            setMessage(response.data.filter(infos => infos.ID < 8));
          else
            setMessage(response.data.filter(infos => infos.ID > 7));

          setLoading(false);
          setOpen(false);
        })
        .catch(function (error) {
          if (error.response.status === 406){
            if (fase === 'FaseGrupos')
              setMessage(error.response.data.filter(infos => infos.ID < 8));
            else
              setMessage(error.response.data.filter(infos => infos.ID > 7));
            
            setLoading(false);
            setOpen(false);
            setOpenDialog(true);
          }
          else{
            localStorage.removeItem("token_bolaodojuca");
            navigate('/');
          }
        });
      
  },[fase, navigate]);



  return (
    <div className='Main_Home'>
      <Header fase={fase}/>
      <div className="Body_Grupos">
        <Dialog open={openDialog} 
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
            <DialogTitle>AVISO!</DialogTitle>
            <DialogContent>
              <DialogContentText>O prazo para efetuar os chutes expirou!</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose}>OK</Button>
            </DialogActions>

        </Dialog>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        />
        {loading
          ? <div style={{display: 'flex', justifyContent: 'center'}}>
              <CircularProgress status="loading" />
            </div>
          : 
        
        <Table sx={{ maxWidth: 375 }} >
          <TableHead>
            <TableRow>
              <TableCell align="center" theme={theme}>Time 1</TableCell>
              <TableCell align="center" theme={theme} colSpan={3}>VS</TableCell>
              <TableCell align="center" theme={theme}>Time 2</TableCell>
            </TableRow>
          </TableHead>
          
          {infos.map((infos) => (
            <TableBody key={infos.FASE}>
              <TableRow >
                <TableCell className="nome_rodada" colSpan={5} theme={theme}>{infos.FASE}</TableCell>
              </TableRow>
              <CadastroChutes key={infos.ID+fase} partidas={infos.JOGOS} funcao1={handleResultado1} funcao2={handleResultado2}/>
            </TableBody>
          ))}
        </Table>
}
        <div className="botao_enviarChutes">
        <Button variant="contained" onClick={handleEnviaChutes} disabled={buttonDisabeld}>Enviar Chutes</Button>
        </div>
        
      </div>
    </div>
  );
}



export default Chutes;

