import { Backdrop, CircularProgress } from "@mui/material";
import axios from "axios";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Grupos from "./components/Grupos";
import Header from "./components/Header";

import './FaseGrupos.css';



function FaseGrupos({fase}) { 
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [infos, setMessage] = useState([]);
  let navigate = useNavigate(); 
  useEffect(() => {
    setLoading(true);
    setOpen(true);
    axios.get(process.env.REACT_APP_BACKEND+'backend/grupos/', 
        { 
          headers: { 'Authorization': `Bearer ${localStorage.getItem("token_bolaodojuca")}` }
        })
        .then(function (response) {
          setMessage(response.data);
          setLoading(false);
          setOpen(false);
        })
        .catch(function (error) {
          localStorage.removeItem("token_bolaodojuca");
          navigate('/');
        });
      
  },[navigate]);

  return (

    <div className='Main_Home'>
      <Header fase={fase}/>
      <div className="Body_Grupos">
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        />
        {loading
          ? <div style={{display: 'flex', justifyContent: 'center'}}>
              <CircularProgress status="loading" />
            </div>
          : 
          <Grupos grupos={infos}/>
        }
      </div>
    </div>

  );
}



export default FaseGrupos;

