import { Backdrop, CircularProgress, createTheme, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import axios from "axios";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Header from "./components/Header";
import Partidas from "./components/Partidas";

import './Jogos.css';



function Jogos({fase}) { 

  const theme = createTheme({
    components: {
      // Name of the component
      MuiTableCell: {
        styleOverrides: {
     
          head: {
            // Some CSS
            fontSize: '1.1rem',
            color: 'grey',
            padding: '0'
            
          },
          body: {
            fontSize: '14px',
            color: 'white',
            textAlign: 'center',
            padding: '10px 0 0 0px',
            border: '1',
            fontFamily: 'Inter',
            lineHeight: '20px'
          }
        },
      },
    },
  });

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [infos, setMessage] = useState([]);
  let navigate = useNavigate(); 
  useEffect(() => {
    setLoading(true);
    setOpen(true);
    axios.get(process.env.REACT_APP_BACKEND+'backend/jogos/', 
        { 
          headers: { 'Authorization': `Bearer ${localStorage.getItem("token_bolaodojuca")}` }
        })
        .then(function (response) {
          if (fase === 'FaseGrupos')
            setMessage(response.data.filter(infos => infos.ID < 8));
          else
            setMessage(response.data.filter(infos => infos.ID > 7));
          setLoading(false);
          setOpen(false);
        })
        .catch(function (error) {
          localStorage.removeItem("token_bolaodojuca");
          navigate('/');
        });
      
  },[fase, navigate]);

  return (
    <div className='Main_Home'>
      <Header fase={fase}/>
      <div className="Body_Grupos">
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        />
        {loading
          ? <div style={{display: 'flex', justifyContent: 'center'}}>
              <CircularProgress status="loading" />
            </div>
          : 
        <Table sx={{ maxWidth: 375 }} >
          <TableHead>
            <TableRow>
              <TableCell align="center" theme={theme}>Time 1</TableCell>
              <TableCell align="center" theme={theme}>VS</TableCell>
              <TableCell align="center" theme={theme}>Time 2</TableCell>
              <TableCell align="center" theme={theme}>Data</TableCell>
            </TableRow>
          </TableHead>
          
          {infos.map((infos) => (
            <TableBody key={infos.FASE}>
              <TableRow >
                <TableCell className="nome_rodada" colSpan={4} theme={theme}>{infos.FASE}</TableCell>
              </TableRow>
              <Partidas key={infos.ID+fase} partidas={infos.JOGOS} />
            </TableBody>
          ))}
        </Table>
      }
      </div>
    </div>
  );
}



export default Jogos;

