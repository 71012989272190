
import jwtDecode from 'jwt-decode';
import { useState } from 'react';
import TemporaryDrawer from '../Menu';
import './header.css';



function Header({fase}) { 
    let token = localStorage.getItem("token_bolaodojuca");
    if (token !== null && token !== "")
        token = jwtDecode(token);
    else
        token = {"nome":""};


    const [usuario] = useState(
      token.nome
    );
    return (
        <div className="Header_Home">
            <div className="titulo_home">
            <TemporaryDrawer usuario={usuario} fase={fase}/>
            <div className="Logo_home"></div>
            <div className="h2_home">Bolão do Juca</div>
            </div>
        </div>
    );
}

export default Header;

