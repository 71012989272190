import jwtDecode from "jwt-decode";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AdmProtected = ({pagina, children}) => {
    let navigate = useNavigate(); 
    
    let token = localStorage.getItem("token_bolaodojuca")
    let auth = false;

    if (token !== null && token !== ""){
        let data = jwtDecode(token);
        
        if (data.nivel === 0){
            auth = true;
        }
    }    

   
    useEffect(() => {
        if (!auth){
            navigate('/');   
            localStorage.removeItem("token_bolaodojuca")
        }

    },[navigate, auth]);

    return children;
   
};
export default AdmProtected;