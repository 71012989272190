import TextField from '@mui/material/TextField'
import styled from '@emotion/styled';
import { Backdrop, Button, CircularProgress } from '@mui/material';
import '../Login/Login.css';
import {useState } from 'react';
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom';

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'white',
  },
  '& label':{
    color: 'white'
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: 'white',
  },
  'input':{
    color: 'white'
  },
});

function CadastroNovaSenha() {
  let navigate = useNavigate(); 
  let {id} = useParams();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensagem, setMensagem] = useState("");

  const [password, setPassword] = useState("");
  const [repassword, setRePassword] = useState("");

  const [erroSenha, setErroSenha] = useState(false);

  const [cadastro, setCadastro] = useState(true);


  const handlePasswordOnChange = (e) => {
    setPassword(e.target.value)
    if (repassword !== e.target.value){
      setMensagem("As senhas são diferentes!");
      setErroSenha(true);
    }
    else{
      setMensagem("");
      setErroSenha(false);
    }
    if (repassword !== "" && repassword === e.target.value){
      setCadastro(false);
    }
    else{
      setCadastro(true);
    }
  }

  const handleRePasswordOnChange = (e) => {
    setRePassword(e.target.value)
    if (e.target.value !== password){
      setMensagem("As senhas são diferentes!");
      setErroSenha(true);
    }
    else{
      setMensagem("");
      setErroSenha(false);
    }
    if (password !== "" && e.target.value === password){
      setCadastro(false);
    }
    else{
      setCadastro(true);
    }
  }

  const handleVoltar = (e) => {
    navigate('/');
  }

  const handleLoginLClick = (e) => {
    var md5 = require('md5');
    setLoading(true);
    setOpen(true);
    setMensagem("");
        axios.post('/backend/cadastro_novasenha.php', {
          id: id,
          password: md5(password)
        })
        .then(function (response) {
            localStorage.setItem("token_bolaodojuca",response.data)
            navigate('/home');
        })
        .catch(function (error) {
            setLoading(false);
            setOpen(false);
          if (error.response.status === 404){
            setMensagem(error.response.data);
          }
          else{
            navigate('/');
          }
        });

  }


  return (
    <div className="Main">
      <div className="Header_Login">
        <h2 className='h2_login'>Bolão do Juca</h2>
        <div className="Logo_Login"></div>
      </div>
      <div className="Login">
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        />
        {loading
          ? <div style={{display: 'flex', justifyContent: 'center'}}>
              <CircularProgress status="loading" />
            </div>
          : 
        <div className="Infos">
          <CssTextField type="password" error={erroSenha} value={password} onChange={handlePasswordOnChange} margin="normal" id="senha1" label="Senha" variant="standard" />
          <CssTextField type="password" error={erroSenha} value={repassword} onChange={handleRePasswordOnChange} margin="normal" id="senha2" label="Repetir Senha" variant="standard" />
          
          <Button disabled={cadastro} onClick={handleLoginLClick} variant="contained">Cadastrar Nova Senha</Button>
          <Button onClick={handleVoltar} variant="text">Voltar</Button>
          <div className="mensagem_login">{mensagem}</div>
        </div>
}
      </div>
    </div>
  );
}

export default CadastroNovaSenha;
