import { Accordion, AccordionSummary, Typography, AccordionDetails, Table, TableHead, TableRow, TableCell, TableBody, createTheme } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grupo from "../Grupo";
import "./grupos.css"




function Grupos({grupos}) {
  const theme = createTheme({
    components: {
      // Name of the component
      MuiTableCell: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            fontSize: '1.2rem',
            color: 'grey'
          },
        },
      },
    },
  });   

  return (
    <>
      {grupos.map((grupos) => (
              <Accordion key={grupos.ID}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{
                  color: 'white',
                  border: 1
                }}/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  backgroundColor: "#1E2026",
                  color: 'white',
                  bordercolor: 'white'
                }}
              >
                <Typography key={grupos.ID}>GRUPO {grupos.ID}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell theme={theme}>Time</TableCell>
                      <TableCell theme={theme}>Pontos</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <Grupo key={grupos.ID} times={grupos.TIMES}/>
                  </TableBody>
                </Table>
                  
              </AccordionDetails>
            </Accordion>
      ))}

    </>
  );
}



export default Grupos

