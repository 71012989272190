import { Backdrop, Button, CircularProgress, createTheme, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import axios from "axios";
import jwtDecode from "jwt-decode";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Header from "./components/Header";

import './FaseGrupos.css';



function Resultados({fase}) { 
  const theme = createTheme({
    components: {
      // Name of the component
      MuiTableCell: {
        styleOverrides: {
     
          head: {
            // Some CSS
            fontSize: '1 rem',
            color: 'grey',
            paddingBottom: '0'
          },
          body: {
            color: 'white',
            padding: '0'
          }
        },
      },
    },
  });

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);


  const [infos, setMessage] = useState([]);
  let navigate = useNavigate(); 

  const handleDialogClose = (e) => {
    setOpenDialog(false);
  }
  
  const handleVerChutes = (e) => {
    let token = localStorage.getItem("token_bolaodojuca")
    let data = jwtDecode(token);
    if (parseInt(data.id) === parseInt(e.target.id)){
      if (fase === "FaseGrupos"){
        navigate('/fasedegrupos/chutes');
      }
      else{
        navigate('/eliminatorias/chutes');
        
      }
    }
    else if (fase === "FaseGrupos"){
      if (data.chute === 1){
        navigate('/fasedegrupos/chutes/'+e.target.id);
      }
    }
    else{
      if ( (data.fase === "OITAVAS" && data.chute >= 8) ||
        (data.fase === "QUARTAS" && data.chute >= 9) ||
        (data.fase === "SEMIFINAL" && data.chute >= 10) ||
        (data.fase === "TERCEIRO" && data.chute >= 11) )
        navigate('/eliminatorias/chutes/'+e.target.id)
    }
    setOpenDialog(true);
  }
  

  useEffect(() => {
    setLoading(true);
    setOpen(true);
    axios.get(process.env.REACT_APP_BACKEND+'backend/pontuacao/', 
        { 
          params: {fase:fase},
          headers: { 'Authorization': `Bearer ${localStorage.getItem("token_bolaodojuca")}` }
        })
        .then(function (response) {
          setMessage(response.data);
          setLoading(false);
          setOpen(false);
        })
        .catch(function (error) {
          localStorage.removeItem("token_bolaodojuca");
          navigate('/');
        });
      
  },[navigate, fase]);

  return (

    <div className='Main_Home'>
      <Header fase={fase}/>
      <div className="Body_Grupos">
        <Dialog open={openDialog} 
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
            <DialogTitle>AVISO!</DialogTitle>
            <DialogContent>
              <DialogContentText>Você precisa fazer seus chutes antes de acessar os chutes do usuário!</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose}>OK</Button>
            </DialogActions>

        </Dialog>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        />
        {loading
          ? <div style={{display: 'flex', justifyContent: 'center'}}>
              <CircularProgress status="loading" />
            </div>
          :
        <Table sx={{ maxWidth: 375 }}>
          <TableHead>
            <TableRow>
              <TableCell align="center" theme={theme}>Posição</TableCell>
              <TableCell align="center" theme={theme}>Nome</TableCell>
              <TableCell align="center" theme={theme}>Pontos</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {infos.map((infos) => (
            <TableRow key={infos.NOME}>
                <TableCell sx={{ maxWidth: 90 }} align="center" theme={theme}>{infos.POSICAO}</TableCell>
                <TableCell sx={{ maxWidth: 185 }} align="center" theme={theme}><Button id={infos.ID} onClick={handleVerChutes} size='small' ariant="text">{infos.NOME}</Button></TableCell>
                <TableCell sx={{ maxWidth: 90 }} align="center" theme={theme}>{infos.PONTOS}</TableCell>
            </TableRow>))}
          </TableBody>
        </Table>
      }
      </div>
    </div>

  );
}



export default Resultados;

