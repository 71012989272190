import jwtDecode from "jwt-decode";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Protected = ({pagina, children}) => {
    let navigate = useNavigate(); 
    
    let token = localStorage.getItem("token_bolaodojuca")
    let auth = false;
    let aceite = 0;
    let fase = children.props.fase;
    let chute = false;

    if (token !== null && token !== ""){
        let data = jwtDecode(token);
        let now = parseInt(Date.now()/1000);
        if (now < data.exp)
            auth = true;

        if (fase !== "copa2022"){
            if (fase === "FaseGrupos"){
                if (data.aceite === 0)
                    aceite = 1;
            }
            else{
                if (data.aceite !== 2)
                    aceite = 2;
            }
        }
        if (pagina === 'chutes_participante'){
            if (fase === "FaseGrupos"){
                if (data.chute === 1)
                    chute = true;
            }
            else{
                if (data.fase === "OITAVAS" && data.chute >= 8)
                    chute = true;
                else if (data.fase === "QUARTAS" && data.chute >= 9)
                    chute = true;
                else if (data.fase === "SEMIFINAL" && data.chute >= 10)
                    chute = true;
                else if (data.fase === "TERCEIRO" && data.chute === 11)
                    chute = true;
            }
        }
    }
   
    useEffect(() => {
        if (!auth){
            navigate('/');   
            localStorage.removeItem("token_bolaodojuca");
        }
        else if (aceite === 1){
            navigate('/fasedegrupos/termo');
        }
        else if (aceite === 2){
            navigate('/eliminatorias/termo');
        }
        else if (!chute && pagina === 'chutes_participante'){
            navigate('/home');
        }


    },[navigate, auth, aceite, chute, pagina]);

    return children;
   
};
export default Protected;