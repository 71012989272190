import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Login from './components/Login';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Home from './Home';
import Protected from './Protected';
import FaseGrupos from './FaseGrupos';
import Resultados from './Resultados';
import Jogos from './Jogos';
import Chutes from './Chutes';
import Termo from './Termo';
import ChutesParticipantes from './ChutesParticipante';
import Cadastro from './components/CadastroUsuario';
import NovaSenha from './components/NovaSenha';
import CadastroNovaSenha from './components/NovaSenha/cadastro';
import AdmProtected from './AdmProtected';
import AdmJogos from './AdmJogos';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path='*' element={<Login />}/>
        <Route path='/' element={<Login/>} />
        <Route path='/cadastro' element={<Cadastro/>} />
        <Route path='/recuperarsenha' element={<NovaSenha/>} />
        <Route path='/recuperarsenha/:id' element={<CadastroNovaSenha/>} />
        <Route path='/home' element={
          <Protected pagina={'home'}>
            <Home fase={'copa2022'}/>
          </Protected> 
        } />

      <Route path='/fasedegrupos/grupos' element={
          <Protected pagina={'grupos'}>
            <FaseGrupos fase={'FaseGrupos'} />
          </Protected> 
        } />

      <Route path='/fasedegrupos/pontuacao' element={
          <Protected pagina={'pontuacao'}>
            <Resultados fase={'FaseGrupos'} />
          </Protected> 
        } />
      
      <Route path='/eliminatorias/pontuacao' element={
          <Protected pagina={'pontuacao'}>
            <Resultados fase={'FaseEliminatorias'} />
          </Protected> 
        } />

      <Route path='/fasedegrupos/jogos' element={
          <Protected pagina={'jogos'}>
            <Jogos fase={'FaseGrupos'} />
          </Protected> 
        } />
      
      <Route path='/eliminatorias/jogos' element={
          <Protected pagina={'jogos'}>
            <Jogos fase={'FaseEliminatorias'} />
          </Protected> 
        } />

      <Route path='/fasedegrupos/chutes' element={
          <Protected pagina={'chutes'}>
            <Chutes fase={'FaseGrupos'} />
          </Protected> 
        } />
      
      <Route path='/eliminatorias/chutes' element={
          <Protected pagina={'chutes'}>
            <Chutes fase={'FaseEliminatorias'} />
          </Protected> 
        } />

      <Route path='/eliminatorias/chutes/:id' element={
          <Protected pagina={'chutes_participante'}>
            <ChutesParticipantes fase={'FaseEliminatorias'} />
          </Protected> 
        } />

      <Route path='/fasedegrupos/chutes/:id' element={
          <Protected pagina={'chutes_participante'}>
            <ChutesParticipantes fase={'FaseGrupos'} />
          </Protected> 
        } />

      <Route path='/fasedegrupos/termo' element={
          <Protected pagina={'termo'}>
            <Termo fase={'FaseGrupos'} />
          </Protected> 
        } />

        <Route path='/eliminatorias/termo' element={
          <Protected pagina={'termo'}>
            <Termo fase={'FaseEliminatorias'} />
          </Protected> 
        } />

      <Route path='/fasedegrupos/adm/jogos' element={
          <AdmProtected pagina={'termo'}>
            <AdmJogos fase={'FaseGrupos'} />
          </AdmProtected> 
        } />

      <Route path='/eliminatorias/adm/jogos' element={
          <AdmProtected pagina={'termo'}>
            <AdmJogos fase={'FaseEliminatorias'} />
          </AdmProtected> 
        } />
        
      </Routes>
    </Router>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
