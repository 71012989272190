import TextField from '@mui/material/TextField'
import styled from '@emotion/styled';
import { Backdrop, Button, CircularProgress } from '@mui/material';
import '../Login/Login.css';
import {useState } from 'react';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'white',
  },
  '& label':{
    color: 'white'
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: 'white',
  },
  'input':{
    color: 'white'
  },
});

function Cadastro() {
  let navigate = useNavigate(); 

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensagem, setMensagem] = useState("");
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [repassword, setRePassword] = useState("");
  const [cadastro, setCadastro] = useState(true);
  const [erroLogin, setErroLogin] = useState(false);
  const [erroSenha, setErroSenha] = useState(false);

  const handleLoginOnChange = (e) => {
    setLogin(e.target.value)
    if (nome !== "" && email !== "" && password !== "" && repassword !== "" & e.target.value !== "" && repassword === password){
      setCadastro(false);
    }
    else{
      setCadastro(true);
    }
  }

  const handleEmailOnChange = (e) => {
    setEmail(e.target.value)
    if (nome !== "" && e.target.value !== "" && password !== "" && repassword !== "" & login !== "" && repassword === password){
      setCadastro(false);
    }
    else{
      setCadastro(true);
    }
  }

  const handleNomeOnChange = (e) => {
    setNome(e.target.value)
    if (e.target.value !== "" && email !== "" && password !== "" && repassword !== "" & login !== "" && repassword === password){
      setCadastro(false);
    }
    else{
      setCadastro(true);
    }
  }

  const handlePasswordOnChange = (e) => {
    setPassword(e.target.value)
    if (repassword !== e.target.value){
      setMensagem("As senhas são diferentes!");
      setErroSenha(true);
    }
    else{
      setMensagem("");
      setErroSenha(false);
    }
    if (nome !== "" && email !== "" && e.target.value !== "" && repassword !== "" & login !== "" && repassword === e.target.value){
      setCadastro(false);
    }
    else{
      setCadastro(true);
    }
  }

  const handleRePasswordOnChange = (e) => {
    setRePassword(e.target.value)
    if (e.target.value !== password){
      setMensagem("As senhas são diferentes!");
      setErroSenha(true);
    }
    else{
      setMensagem("");
      setErroSenha(false);
    }
    if (nome !== "" && email !== "" && password !== "" && e.target.value !== "" & login !== "" && e.target.value === password){
      setCadastro(false);
    }
    else{
      setCadastro(true);
    }
  }

  const handleVoltar = (e) => {
    navigate('/');
  }

  const handleLoginLClick = (e) => {
    setLoading(true);
    setOpen(true);
    setErroLogin(false);
    var md5 = require('md5');
    
        axios.post(process.env.REACT_APP_BACKEND+'backend/cadastro.php', {
          login: login,
          password: md5(password),
          nome: nome,
          email: email
        })
        .then(function (response) {
          localStorage.setItem("token_bolaodojuca",response.data)
          navigate('/home');
        })
        .catch(function (error) {
          setLoading(false);
          setOpen(false);
          if (error.response.status === 409){
            setMensagem(error.response.data);
            setErroLogin(true);
          }
          else{
            navigate('/');
          }
        });

  }


  return (
    <div className="Main">
      <div className="Header_Login">
        <h2 className='h2_login'>Bolão do Juca</h2>
        <div className="Logo_Login"></div>
      </div>
      <div className="Login">
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        />
        {loading
          ? <div style={{display: 'flex', justifyContent: 'center'}}>
              <CircularProgress status="loading" />
            </div>
          : 
        <div className="Infos">
          <CssTextField value={nome} onChange={handleNomeOnChange} margin="normal" id="nome" label="Nome" variant="standard" />
          <CssTextField value={login} error={erroLogin} onChange={handleLoginOnChange} margin="normal" id="login" label="Login" variant="standard" />
          <CssTextField type="password" error={erroSenha} value={password} onChange={handlePasswordOnChange} margin="normal" id="senha1" label="Senha" variant="standard" />
          <CssTextField type="password" error={erroSenha} value={repassword} onChange={handleRePasswordOnChange} margin="normal" id="senha2" label="Repetir Senha" variant="standard" />
          <CssTextField value={email} onChange={handleEmailOnChange} margin="normal" id="email" label="E-Mail" variant="standard" />
          
          <Button disabled={cadastro} onClick={handleLoginLClick} variant="contained">Cadastrar</Button>
          <Button onClick={handleVoltar} variant="text">Voltar</Button>
          <div className="mensagem_login">{mensagem}</div>
        </div>
}
      </div>
    </div>
  );
}

export default Cadastro;
