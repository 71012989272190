import TextField from '@mui/material/TextField'
import styled from '@emotion/styled';
import { Backdrop, Button, CircularProgress } from '@mui/material';
import '../Login/Login.css';
import {useState } from 'react';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'white',
  },
  '& label':{
    color: 'white'
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: 'white',
  },
  'input':{
    color: 'white'
  },
});

function NovaSenha() {
  let navigate = useNavigate(); 

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensagem, setMensagem] = useState("");
  const [login, setLogin] = useState("");

  const [cadastro, setCadastro] = useState(true);
  const [erroLogin, setErroLogin] = useState(false);


  const handleLoginOnChange = (e) => {
    setLogin(e.target.value)
    if (e.target.value !== ""){
      setCadastro(false);
    }
    else{
      setCadastro(true);
    }
  }





  

  const handleVoltar = (e) => {
    navigate('/');
  }

  const handleLoginLClick = (e) => {
    setLoading(true);
    setOpen(true);
    setErroLogin(false);
    setMensagem("");
        axios.post(process.env.REACT_APP_BACKEND+'backend/recuperav2.php', {
          login: login
        })
        .then(function (response) {
            setLoading(false);
            setOpen(false);
            setMensagem(response.data);
        })
        .catch(function (error) {
          setLoading(false);
          setOpen(false);
          if (error.response.status === 404){
            setMensagem(error.response.data);
            setErroLogin(true);
          }
          else{
            navigate('/');
          }
        });

  }


  return (
    <div className="Main">
      <div className="Header_Login">
        <h2 className='h2_login'>Bolão do Juca</h2>
        <div className="Logo_Login"></div>
      </div>
      <div className="Login">
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        />
        {loading
          ? <div style={{display: 'flex', justifyContent: 'center'}}>
              <CircularProgress status="loading" />
            </div>
          : 
        <div className="Infos">
          <CssTextField value={login} error={erroLogin} onChange={handleLoginOnChange} margin="normal" id="login" label="Login" variant="standard" />
          
          <Button disabled={cadastro} onClick={handleLoginLClick} variant="contained">Enviar</Button>
          <Button onClick={handleVoltar} variant="text">Voltar</Button>
          <div className="mensagem_login">{mensagem}</div>
        </div>
}
      </div>
    </div>
  );
}

export default NovaSenha;
