import { Backdrop, Button, Checkbox, CircularProgress } from "@mui/material";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./components/Header";
import TermoEliminatoria from "./components/Termo/eliminatorias";
import TermoFaseGrupos from "./components/Termo/fasegrupos";

import './Termo.css';



function Termo({fase}) { 
  let token = localStorage.getItem("token_bolaodojuca")
  let data = null;
  let aceiteTermo = false;
  if (token !== null && token !== ""){
    data = jwtDecode(token);
    if (data.aceite >= 1 && fase === "FaseGrupos"){
      aceiteTermo = true;
    }
    if (data.aceite === 2 && fase === "FaseEliminatorias"){
      aceiteTermo = true;
    }

  }

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  let termo = 0;
  let navigate = useNavigate();
  const[botaoTermo,setBotaoTermo] = useState(true);

  const handleAceite = (e) => {
    setBotaoTermo(!e.target.checked);
  }

  const handleEnviaAceite = (e) => {
    if (fase === "FaseGrupos")
      termo = 1;
    else
      termo = 2;
    
    setLoading(true);
    setOpen(true);
    setBotaoTermo(true);
    axios.post(process.env.REACT_APP_BACKEND+'backend/termo/', {
          "termo": termo
        },
        { 
          headers: { 'Authorization': `Bearer ${localStorage.getItem("token_bolaodojuca")}` }

        })
        .then(function (response) {
          localStorage.setItem("token_bolaodojuca",response.data);
          if (fase === "FaseGrupos")
            navigate('/fasedegrupos/pontuacao');
          else
            navigate('/eliminatorias/pontuacao');
        })
        .catch(function (error) {
          localStorage.removeItem("token_bolaodojuca");
          navigate('/');
        });
      
  
  }


  return (

    <div className='Main_Home'>
      <Header fase={fase}/>
      <div className="Body_Grupos">
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        />
        {loading
          ? <div style={{display: 'flex', justifyContent: 'center'}}>
              <CircularProgress status="loading" />
            </div>
          : <div className="Termo">
              {fase === "FaseGrupos"
                ? <TermoFaseGrupos />
                : <TermoEliminatoria />
              }
              {aceiteTermo
                ? <>
                    <Checkbox checked disabled /> Eu li os Termos & Condições 
                    <Button disabled className="botao_aceitaTermo" variant="contained">Eu Concordo</Button>       
                  </>
                : <>
                    <Checkbox sx={{color: "white"}} onChange={handleAceite} /> Eu li os Termos & Condições 
                    <Button onClick={handleEnviaAceite} disabled={botaoTermo} className="botao_aceitaTermo" variant="contained">Eu Concordo</Button>       
                  </>
              }
            </div>
        }
        
      </div>
    </div>

  );
}



export default Termo;

