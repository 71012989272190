import TextField from '@mui/material/TextField'
import styled from '@emotion/styled';
import { Backdrop, Button, CircularProgress } from '@mui/material';
import './Login.css';
import { useEffect, useState } from 'react';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'white',
  },
  '& label':{
    color: 'white'
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: 'white',
  },
  'input':{
    color: 'white'
  },
});

function Login() {
  let navigate = useNavigate(); 
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensagem, setMensagem] = useState("");
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
    
  let token = localStorage.getItem("token_bolaodojuca")
  let gotoHome = false;
  if (token !== null && token !== ""){
    gotoHome = true;
  }

  const handleLoginOnChange = (e) => {
    setLogin(e.target.value)
  }


  const handlePasswordOnChange = (e) => {
    setPassword(e.target.value)
  }

  const handleLoginLClick = (e) => {
    setLoading(true);
    setOpen(true);
    var md5 = require('md5');
    
        axios.post(process.env.REACT_APP_BACKEND+'backend/loginv2.php', {
          login: login,
          password: md5(password)
        })
        .then(function (response) {
          localStorage.setItem("token_bolaodojuca",response.data)
          navigate('/home');
        })
        .catch(function (error) {
          setLoading(false);
          setOpen(false);
          setMensagem("Usuário ou Senha inválido!")
          localStorage.removeItem("token_bolaodojuca")
        });

  }

  const handleCadastro = (e) => {
    navigate('/cadastro');
  }

  const handleNovaSenha = (e) => {
    navigate('/recuperarsenha');
  }

  useEffect(() => {
    if (gotoHome)
        navigate('/home');

  },[navigate, gotoHome]);

  return (
    <div className="Main">
      <div className="Header_Login">
        <h2 className='h2_login'>Bolão do Juca</h2>
        <div className="Logo_Login"></div>
      </div>
      <div className="Login">
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        />
        {loading
          ? <div style={{display: 'flex', justifyContent: 'center'}}>
              <CircularProgress status="loading" />
            </div>
          : 
        <div className="Infos">
          <CssTextField value={login} onChange={handleLoginOnChange} margin="normal" id="login" label="Login" variant="standard" />
          <CssTextField type="password" value={password} onChange={handlePasswordOnChange} margin="normal" id="senha" label="Senha" variant="standard" />
          <Button onClick={handleLoginLClick} variant="contained">Acessar</Button>
          <Button onClick={handleNovaSenha} variant="text">Esqueci a senha</Button>
          <Button onClick={handleCadastro} variant="text">Cadastre-se</Button>
          <div className="mensagem_login">{mensagem}</div>
        </div>
        }
      </div>
    </div>
  );
}

export default Login;
