import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import './menu.css';
import MenuIcon from '@mui/icons-material/Menu'
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

export default function TemporaryDrawer(info) {
  let navigate = useNavigate();
  let token = localStorage.getItem("token_bolaodojuca");
  if (token === null && token === ""){
    navigate('/');   
    localStorage.removeItem("token_bolaodojuca");
  }
  let data = jwtDecode(token);

  let menu = [];
  if (data.nivel === 0){
    if (info.fase === "FaseGrupos")
      menu = ['Home', 'Grupos', 'Pontuação', 'AdmJogos'];
    else if (info.fase === "FaseEliminatorias")
      menu = ['Home', 'Pontuação', 'AdmJogos'];
  }
  else if (info.fase === "FaseGrupos")
    menu = ['Home', 'Grupos', 'Chutes', 'Pontuação', 'Jogos', 'Termo'];
  else if (info.fase === "FaseEliminatorias")
    menu = ['Home', 'Chutes', 'Pontuação', 'Jogos', 'Termo'];

  

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleMenuOnClick = (e, index) => {
    if (data.nivel === 0){
      if (info.fase === "FaseGrupos"){
        if (index === 0)
          navigate('/home');
        if (index === 1)
          navigate('/fasedegrupos/grupos');
        if (index === 2)
          navigate('/fasedegrupos/pontuacao');
        if (index === 3)
          navigate('/fasedegrupos/adm/jogos')
      }
      else{
        if (index === 0)
          navigate('/home');
        if (index === 1)
          navigate('/eliminatorias/pontuacao');
        if (index === 2)
          navigate('/eliminatorias/adm/jogos')

      }
    }
    else{ 
      if (info.fase === "FaseGrupos"){
        if (index === 0)
          navigate('/home');
        if (index === 1)
          navigate('/fasedegrupos/grupos');
        if (index === 2)
          navigate('/fasedegrupos/chutes');
        if (index === 3)
          navigate('/fasedegrupos/pontuacao');
        if (index === 4)
          navigate('/fasedegrupos/jogos');
        if (index === 5)
          navigate('/fasedegrupos/termo');
      }
      else if (info.fase === "FaseEliminatorias"){
        if (index === 0)
          navigate('/home');
        if (index === 1)
          navigate('/eliminatorias/chutes');
        if (index === 2)
          navigate('/eliminatorias/pontuacao');
        if (index === 3)
          navigate('/eliminatorias/jogos');
        if (index === 4)
          navigate('/eliminatorias/termo');
      }
    }
  }

  const handleLogoff = (e) => {
    localStorage.removeItem("token_bolaodojuca")  
    navigate('/');   
  }

  const list = (anchor) => (

    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem>
          <ListItemText>{info.usuario}</ListItemText>
          <ListItemButton onClick={handleLogoff}>Logoff</ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        {menu.map((text, index) => (
          <ListItem onClick={event => handleMenuOnClick(event, index)} key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      
    </Box>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <div className='button_menu' onClick={toggleDrawer(anchor, true)}><MenuIcon fontSize="large"/></div>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
