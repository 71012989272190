
import './Home.css';
import {useNavigate } from 'react-router-dom';
import Header from './components/Header';
import { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';

function Home({fase}) {
  let navigate = useNavigate(); 

  const[faseEliminatoria,setFaseEliminatoria] = useState("Entrar");

  let token = localStorage.getItem("token_bolaodojuca")

  let payload = null;
  if (token !== null && token !== "")
    payload = jwtDecode(token);

  useEffect(() => {
    if (payload !== null && payload.fase === 'GRUPOS' && (payload.id !== 1 && payload.id !== 19))
      setFaseEliminatoria("Aguarde");
  },[payload, navigate]);


  const handleFaseGruposOnClick = (e) => {
    navigate('/fasedegrupos/pontuacao')
  }

  const handleFaseElminatoriaOnClick = (e) => {
    if (payload.fase !== 'GRUPOS' || payload.id === 1 || payload.id === 19)
      navigate('/eliminatorias/pontuacao')
  }

  return (

    <div className='Main_Home'>

      <Header fase={fase} />
      <div className='Body'>
        <div className='Body_Button'>
          <div className='Button1_Home' onClick={handleFaseGruposOnClick}>
            <div className='bt_home_img'></div>
            <div className='bt_home_titulo'>
              <div className='bt_home_desc'>Fase de Grupos</div>
              <div className='bt_home_desc'>Entrar</div>
            </div>
          </div>
        </div>
        <div className='Body_Button'>
        <div className='Button1_Home' onClick={handleFaseElminatoriaOnClick}>
            <div className='bt_home_img2'></div>
            <div className='bt_home_titulo'>
              <div className='bt_home_desc'>Fase Eliminatória</div>
              <div className='bt_home_desc'>{faseEliminatoria}</div>
            </div>
          </div>
        
        </div>
      </div>
    </div>
  );
}



export default Home;

