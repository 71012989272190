import { Backdrop, Button, CircularProgress, createTheme, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import axios from "axios";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Header from "./components/Header";
import AdmPartidas from "./components/Partidas/adm";

import './Jogos.css';



function AdmJogos({fase}) { 

  const theme = createTheme({
    components: {
      // Name of the component
      MuiTableCell: {
        styleOverrides: {
     
          head: {
            // Some CSS
            fontSize: '1.1rem',
            color: 'grey',
            padding: '0'
            
          },
          body: {
            fontSize: '14px',
            color: 'white',
            textAlign: 'center',
            padding: '10px 0 0 0px',
            border: '1',
            fontFamily: 'Inter',
            lineHeight: '20px'
          }
        },
      },
    },
  });
  
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [resultados] = useState([]);
  const handleResultado1 = (e) => {
    let valor;
    if (e.target.value === '' || e.target.value === null)
      valor = -1;
    else
      valor = parseInt(e.target.value)

    let index = resultados.findIndex((obj => obj.ID === parseInt(e.target.id)))
    if (index === -1){
      resultados.push(
        {
          'ID': parseInt(e.target.id),
          'R1': valor,
          'R2': -1
        }
      );
    }
    else{
      resultados[index].R1 = valor;
    }

  }
  const handleResultado2 = (e) => {
    let valor;
    if (e.target.value === '' || e.target.value === null)
      valor = -1;
    else
      valor = parseInt(e.target.value)

    let index = resultados.findIndex((obj => obj.ID === parseInt(e.target.id)))
    if (index === -1){
      resultados.push(
        {
          'ID': parseInt(e.target.id),
          'R1': -1,
          'R2': valor
        }
      );
    }
    else{
      resultados[index].R2 = valor;
    }

  }


  const handleEnviaChutes = () =>{
    setLoading(true);
    setOpen(true);
    let path = "";
    if (fase === "FaseGrupos"){
      path = "cadastra_resultadosGRU.php";
    }
    else{
      path = "cadastra_resultadosELI.php"

    }
    axios.post(process.env.REACT_APP_BACKEND+'backend/adm/'+path, 
    JSON.stringify(resultados),
      {headers: { 'Authorization': `Bearer ${localStorage.getItem("token_bolaodojuca")}` }}

      )
        .then(function (response) {
          window.location.reload();
        })
        .catch(function (error) {
          localStorage.removeItem("token_bolaodojuca");
          navigate('/');
        });

  }

  const [infos, setMessage] = useState([]);
  let navigate = useNavigate(); 
  useEffect(() => {
    setLoading(true);
    setOpen(true);
    axios.get(process.env.REACT_APP_BACKEND+'backend/adm/lista_jogos.php', 
        { 
          headers: { 'Authorization': `Bearer ${localStorage.getItem("token_bolaodojuca")}` }
        })
        .then(function (response) {
          if (fase === 'FaseGrupos')
            setMessage(response.data.filter(infos => infos.ID < 8));
          else
            setMessage(response.data.filter(infos => infos.ID > 7));

          setLoading(false);
          setOpen(false);
          
        })
        .catch(function (error) {
          localStorage.removeItem("token_bolaodojuca");
          navigate('/');
        });
      
  },[fase, navigate]);



  return (
    <div className='Main_Home'>
      <Header fase={fase}/>
      <div className="Body_Grupos">
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        />
        {loading
          ? <div style={{display: 'flex', justifyContent: 'center'}}>
              <CircularProgress status="loading" />
            </div>
          : 
        
        <Table sx={{ maxWidth: 375 }} >
          <TableHead>
            <TableRow>
              <TableCell align="center" theme={theme}>Time 1</TableCell>
              <TableCell align="center" theme={theme} colSpan={3}>VS</TableCell>
              <TableCell align="center" theme={theme}>Time 2</TableCell>
            </TableRow>
          </TableHead>
          
          {infos.map((infos) => (
            <TableBody key={infos.FASE}>
              <TableRow >
                <TableCell className="nome_rodada" colSpan={5} theme={theme}>{infos.FASE}</TableCell>
              </TableRow>
              <AdmPartidas key={infos.ID+fase} partidas={infos.JOGOS} funcao1={handleResultado1} funcao2={handleResultado2}/>
            </TableBody>
          ))}
        </Table>
}
        <div className="botao_enviarChutes">
        <Button variant="contained" onClick={handleEnviaChutes}>Enviar Chutes</Button>
        </div>
        
      </div>
    </div>
  );
}


export default AdmJogos;

