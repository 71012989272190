import {TableRow, TableCell, createTheme, TextField } from "@mui/material";





function AdmPartidas(infos) {
  const theme = createTheme({
    components: {
      // Name of the component
      MuiTableCell: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            fontSize: '0.8rem',
            color: 'white',
            padding: '0',
            border: '0'
          }
        },
      },
    },
  });

  return (
    <>
        {infos.partidas.map((partidas) => (
            <TableRow key={partidas.ID}>
                <TableCell sx={{ maxWidth: 74 }} align="center" theme={theme}>{partidas.TIME1}</TableCell>
                <TableCell sx={{ maxWidth: 50 ,  border: 1, borderColor: 'white'}} align="center" theme={theme}><TextField defaultValue={partidas.RESULTADO1} sx={{ input: { color: 'white', textAlign: 'center'} }} required type="number" id={partidas.ID} time='1' onChange={infos.funcao1} /></TableCell>
                <TableCell sx={{ maxWidth: 21 }} align="center" theme={theme}>X</TableCell>
                <TableCell sx={{ maxWidth: 50 , border: 1, borderColor: 'white'}} align="center" theme={theme}><TextField defaultValue={partidas.RESULTADO2} sx={{ input: { color: 'white', textAlign: 'center'} }} required type="number" id={partidas.ID} onChange={infos.funcao2}/></TableCell>
                <TableCell sx={{ maxWidth: 74 }} align="center" theme={theme}>{partidas.TIME2}</TableCell>
            </TableRow>
        ))}
    </>
  );
}


export default AdmPartidas

