import {TableRow, TableCell, createTheme } from "@mui/material";





function Partidas({partidas}) {
  const theme = createTheme({
    components: {
      // Name of the component
      MuiTableCell: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            fontSize: '0.8rem',
            color: 'white',
            padding: '0',
            border: '0'
          }
        },
      },
    },
  });
 

  return (
    <>
        {partidas.map((partidas) => (
            <TableRow key={partidas.ID}>
                <TableCell sx={{ maxWidth: 74 }} align="center" theme={theme}>{partidas.TIME1}</TableCell>
                <TableCell sx={{ maxWidth: 70 }} align="center" theme={theme}>{partidas.RESULTADO1} X {partidas.RESULTADO2}</TableCell>
                <TableCell sx={{ maxWidth: 74 }} align="center" theme={theme}>{partidas.TIME2}</TableCell>
                <TableCell sx={{ maxWidth: 157 }} align="center" theme={theme}>{partidas.DATA}</TableCell>
            </TableRow>
        ))}
    </>
  );
}



export default Partidas

