import { createTheme, TableCell, TableRow } from '@mui/material';
import './grupo.css'

function Grupo({times}) {
    const theme = createTheme({
        components: {
          // Name of the component
          MuiTableCell: {
            styleOverrides: {
              // Name of the slot
              root: {
                // Some CSS
                fontSize: '1rem',
                color: 'white'
              },
            },
          },
        },
      });

    return (
    <>
    {times.map((times) => (
        <TableRow key={times.ID}>
            <TableCell theme={theme}>{times.NOME}</TableCell>
            <TableCell theme={theme}>{times.PONTOS}</TableCell>
        </TableRow>
    

    )
        
        )}
        </>
  );
}



export default Grupo

