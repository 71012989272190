


function TermoEliminatoria() { 
    return (
        <>
          <h3>Termos & Condi&ccedil;&otilde;es</h3>
          <h4>Fase de Grupos:</h4>
          <h5>1- DO CADASTRO:</h5>
          <p>O cadastro na p&aacute;gina do bol&atilde;o &eacute; gratuito.</p>
          <p>Para voc&ecirc; participar do bol&atilde;o <b>Fase de Grupos</b> ser&aacute; cobrado uma taxa, que esta ser&aacute; usada como pr&ecirc;mio para o(s) vencedor(es). S&oacute; poder&aacute; participar do bol&atilde;o quem estiver cadastrado na p&aacute;gina.</p>
          <p>Os pontos s&oacute; ser&atilde;o contabilizados ap&oacute;s a confirma&ccedil;&atilde;o do pagamento da taxa de participa&ccedil;&atilde;o.</p>
          <p>Os cancelamentos do bol&atilde;o poder&atilde;o ser feitos at&eacute; 24h antes do in&iacute;cio da Copa.</p>
          <p>E-Mail para contato: suporte@bolaodojuca.diasferraz.com</p>
          <h5>2- DO PAGAMENTO:</h5>
          <p>Valor de participação: R$ 20,00</p>
          <p>O pagamento deverá ser feito até o dia de início da Fase de Eliminatória (03/12/2022)</p>
          <p>O pagemento deverá ser feito em uma das opções:</p>
          <ul>
            <li>PIX: 05728397824 (Rita Elena Rosolen)</li>
            <li>Espécie: Juca</li>
          </ul>
          <h5>3- DO LAN&Ccedil;AMENTO DOS PALPITES:</h5>
          <p>Os palpites dever&atilde;o ser lan&ccedil;ados at&eacute; o hor&oacute;rio do in&iacute;cio do primeiro jogo da fase correspondente:</p>
          <ul>
            <li>Oitavas de Final: 03/12 - 12h</li>
            <li>Quartas de Final: 09/12 - 12h</li>
            <li>Semifinal: 13/12 - 16h </li> 
            <li>Terceiro lugar e FINAL: 17/12 - 12h</li>
          </ul>
                    
          <p>Os palpites tamb&eacute;m poder&atilde;o ser enviados por e-mail, para que seja incluido na p&aacute;gina, seguindo o mesmo crit&eacute;rio de prazo.</p>
          <p>Depois de lan&ccedil;ados os palpites, apenas os administradores t&ecirc;m permiss&atilde;o para alter&aacute;-los , para isso, ser&aacute; necess&aacute;rio enviar um e-mail, com o palpite correto, seguindo o mesmo crit&eacute;rio de prazo.</p>
          <h5>4- DA PONTUA&Ccedil;&Atilde;O:</h5>
          <p>A pontua&ccedil;&atilde;o ser&aacute; gerada automaticamente pelo sistema web seguindo regras pr&eacute;-estabelecidas. Ser&aacute; atualizada em at&eacutes 24h ap&oacute;s as partidas.</p>
          <p>Regras de pontua&ccedil;&atilde;o:</p>
          <ul>
            <li>Acertar o placar: 10 pontos;</li>
            <li>Acertar o vencedor e o placar de um dos times: 7 pontos;</li>
            <li>Acertar somente o vencedor sem acertar o placar dos times: 5 pontos;</li>
            <li>Acertar os gols de um dos times sem acertar mais nada: 2 pontos.</li>
          </ul>
          <p>O placar para contagem no bol&atilde;o ser&aacute; o do tempo regulamentar (NÃO serão considerados gols na prorrogação e/ou nos pênaltis).</p>
          <h5>5- DO PR&Ecirc;MIO:</h5>
          <p>O pr&ecirc;mio ser&aacute; de 95% da soma das taxas de inscri&ccedil;&otilde;es, sendo os 5% restantes destinados a organiza&ccedil;&atilde;o e manuten&ccedil;&atilde;o do bol&atilde;o.</p>
          <h5>6- DOS VENCEDORES:</h5>
          <p>Ser&aacute; dado como vencedor(es) o(s) palpiteiro(s) que somar(em) o maior n&uacute;mero de pontos em todas as rodadas. Em caso de empate ser&aacute; dividido em partes iguais.</p>
        </> 
    );
}

export default TermoEliminatoria;

